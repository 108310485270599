import { useTextFieldInput } from '@fingo/lib/hooks';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PilotBackground from './components/PilotBackground';
import InvoiceSimulatorVariables from './components/InvoiceSimulatorVariables';
import SimulationAmounts from '../simulator/SimulationAmounts';
import QuickFlippyRegister from './components/QuickFlippyRegister';

const SimulatorRegister = () => {
  const [amount, _setAmount] = useState(10000000);
  const [daysToPay, setDaysToPay] = useTextFieldInput(30);
  const [client, setClient] = useState();
  const [debtor, setDebtor] = useState();
  return (
    <PilotBackground showPlane>
      <Grid
        container
        py={1}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          px={{
            xs: 2,
            md: 8,
          }}
        >
          <Typography align="left" variant="h3" paragraph>
            <Typography component="span" color="primary" variant="h3">Simula</Typography> {' '}
            automáticamente el anticipo de tus facturas.
          </Typography>
          <Typography align="left" variant="h5" paragraph>
            Usa el siguiente simulador:
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={7.5}
          justifyContent="center"
          px={{
            xs: 2,
            md: 8,
          }}
        >
          <Stack
            sx={{
              width: {
                xs: '100%',
                md: 1100,
              },
              mr: 'auto',
              ml: 'auto',
            }}
            maxWidth="100%"
          >
            <InvoiceSimulatorVariables
              setClient={setClient}
              setDebtor={setDebtor}
              amount={amount}
              _setAmount={_setAmount}
              daysToPay={daysToPay}
              _setDaysToPay={setDaysToPay}
              showSliders={false}
            />
            <Typography align="justify" variant="h5" sx={{ mt: 2 }} paragraph gutterBottom>
              La simulación solicitada es la siguiente:
            </Typography>
            <SimulationAmounts
              client={client}
              debtor={debtor}
              amount={amount}
              days={daysToPay}
              showRegisterButton={false}
            />
          </Stack>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={4.5}
          justifyContent={{
            xs: 'center',
            md: 'flex-start',
          }}
        >
          <QuickFlippyRegister />
        </Grid>
      </Grid>
    </PilotBackground>
  );
};

export default SimulatorRegister;
