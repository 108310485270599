import { useDynamicFingoLogo } from '@fingo/lib/hooks';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { LokalLogo as Lokal } from '@fingo/lib/assets';
import React from 'react';

const LokalLogo = () => {
  const fingoLogo = useDynamicFingoLogo();
  return (
    <Stack>
      <Icon sx={{ height: 80, width: '100%' }}>
        <img src={Lokal} style={{ height: '100%' }} alt="sii" />
      </Icon>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          top: 20,
        }}
      >
        <Typography variant="h5">by</Typography>
        <Icon sx={{ width: 80, height: 'auto' }}>
          <img src={fingoLogo} style={{ height: '100%', width: '100%' }} alt="sii" />
        </Icon>
      </Grid>
    </Stack>
  );
};

export default LokalLogo;
