import FloatingComponent from '@fingo/lib/components/animations/FloatingComponent';
import React from 'react';

import getCdnUrl from '@fingo/lib/helpers/get-cdn-url';

import useIsMobile from '@fingo/lib/hooks/useIsMobile';

const PaperPlane = getCdnUrl('/common_assets/assets/illustrations/paper_plane.png');
const TwistedDottedLine = getCdnUrl('/common_assets/assets/illustrations/twisted_dotted_line.png');

const styles = {
  paperPlane: {
    width: '10rem',
    transform: 'scaleX(-1) rotate(30deg)',
  },
  twistedDottedLine: {
    position: 'absolute',
    width: '10rem',
    right: '0vw',
    top: '140px',
    zIndex: 0,
  },
};

const FloatingPlane = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <FloatingComponent
        speedX={-0.1}
        speedY={-0.1}
        position="absolute"
        top={40}
        right={100}
        zIndex={3}
        display={{
          xs: 'none',
          md: 'inherit',
        }}
      >
        <img src={PaperPlane} alt="paper-plane" style={styles.paperPlane} />
      </FloatingComponent>
      <img
        src={TwistedDottedLine}
        alt="dotted-line"
        style={{
          ...styles.twistedDottedLine,
          display: isMobile ? 'none' : 'inherit',
          zIndex: 1,
        }}
      />
    </>
  );
};

export default FloatingPlane;
