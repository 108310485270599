import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import PilotBackground from './components/PilotBackground';
import NpsProvider from '../nps/NpsProvider';

const LandingNPS = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const npsId = queryParams.get('nps');
  const grade = queryParams.get('grade');

  return (
    <NpsProvider npsId={npsId} presetGrade={grade} timeout={0}>
      <PilotBackground showPlane>
        <Stack
          sx={{
            width: '100%',
            py: 1,
            mr: 'auto',
            ml: 'auto',
            px: {
              xs: 1,
              md: 4,
            },
          }}
          spacing={3}
        >
          <Box margin={10}>
            <Typography align="center" variant="h1">
              En Fingo nos interesa tu opinión
            </Typography>
          </Box>
          <Box margin={10}>
            <Typography align="center" variant="h5">
              ¡Gracias por responder!
            </Typography>
          </Box>
        </Stack>
      </PilotBackground>
    </NpsProvider>
  );
};

export default LandingNPS;
