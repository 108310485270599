import Grid from '@mui/material/Grid';
import React from 'react';
import StepsToFinance from './components/StepsToFinance';
import PilotBackground from './components/PilotBackground';
import AuthPaper from '../papers/AuthPaper';

const LandingRegisterForm = () => (
  <PilotBackground showPlane>
    <Grid container height="fit-content">
      <Grid
        container
        item
        xs={12}
        md={6}
        alignItems="flex-start"
        justifyContent="center"
        pt={{
          xs: 4,
          md: 10,
        }}
        display={{
          xs: 'none',
          md: 'inherit',
        }}
      >
        <StepsToFinance />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        pt={{
          xs: 4,
          md: 6,
        }}
        justifyContent={{
          xs: 'center',
          md: 'auto',
        }}
      >
        <AuthPaper
          width={{ xs: '90%', sm: 500 }}
          mx="auto"
          py={4}
          sx={{
            borderRadius: '20px',
            left: '50%',
            top: '50%',
          }}
        />
      </Grid>
    </Grid>
  </PilotBackground>
);

export default LandingRegisterForm;
