import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useHistory } from 'react-router-dom';
import { REQUEST_PRE_OFFERS_EVALUATION } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { useIsLogged } from '@fingo/lib/hooks';
import PilotBackground from './components/PilotBackground';

const EvaluateInvoices = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const invoicesIds = queryParams.get('invoices')?.split(',') || [];
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [message, setMessage] = useState(`Se mandaron a evaluar tus ${invoicesIds.length} facturas`);
  const isLogged = useIsLogged();
  if (invoicesIds.length === 0 || !isLogged) {
    history.push('/app/sales/factoring/evaluation');
  }

  const [evaluationRequest] = useMutation(REQUEST_PRE_OFFERS_EVALUATION, {
    onCompleted: (data) => {
      setLoading(false);
      setCompleted(true);
      setMessage(`Tus ${data.requestPreOfferEvaluation.invoicesEvaluated.length} facturas han sido enviadas a evaluar exitosamente`);
    },
    onError: (e) => {
      setLoading(false);
      setMessage(e.message);
    },
    refetchQueries: ['myOffers'],
  });

  useEffect(() => {
    const evaluatePreoffers = () => {
      setLoading(true);
      evaluationRequest({
        variables: {
          invoiceIds: invoicesIds,
        },
      });
    };
    evaluatePreoffers();
  }, []);

  const handleButtonClick = () => {
    if (completed) {
      history.push('/app/sales/factoring/evaluation');
    }
  };

  return (
    <PilotBackground showPlane>
      <Stack
        sx={{
          width: '100%',
          py: 1,
          mr: 'auto',
          ml: 'auto',
          px: {
            xs: 1,
            md: 4,
          },
        }}
        spacing={3}
      >
        <Box margin={8} align="center">
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Box margin={10}>
                {completed ? (
                  <Typography align="center" variant="h1">
                    ¡Enhorabuena!
                  </Typography>
                ) : (
                  <Typography align="center" variant="h1">
                    Oops! Hubo un error al evaluar a tus facturas:
                  </Typography>
                )}
              </Box>
              <Typography align="center" variant="h5">
                {message}
              </Typography>
              <Box>
                <Button
                  id="landing-evaluate-invoices-button"
                  onClick={handleButtonClick}
                  align="center"
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{ marginTop: '20px' }}
                >
                  Ir a mis evaluaciones
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Stack>
    </PilotBackground>
  );
};

export default EvaluateInvoices;
